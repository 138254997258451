.login_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include ipad {
    flex-direction: column;
    padding: 30px;
  }

  input{
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }
  }

  .login_field_container {
    background-color: $white;
    width: 450px;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 9px 0px rgba(50, 71, 92, 0.06);
    height: fit-content;
    margin: auto;

    @include ipad {
      margin-bottom: 30px;
    }

    @include phone {
      width: 100%;
      padding: 20px 15px;
    }

    .logo_filed {
      margin-bottom: 32px;

      .login_logo {
        width: 100%;
      }
    }

    .text_filed {
      margin-bottom: 18px;

      .welcome_text {
        color: $black;
        font-size: 20px;
        font-weight: 800;
        margin: 0;
        padding: 0;
        line-height: 32px;
      }

      .signin_text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $light-text-secondary;
        margin: 0;
        padding: 0;
      }
    }

    .login_checkbox {
      color: $light-text-primary;
      border-color: $light-text-primary;

      svg {
        fill: $light-text-disable;
      }
    }
  }

  .login_image_field {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url("../images/login.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login_image {
    width: 100%;
  }
}