// Sidebar dark

.app-sidebar {
  &--dark {
    .app-sidebar--header {
      background: transparent;
      margin-top: 51px;

      .collapse-sidebar-btn {
        background: transparent;
        color: rgba($white, 0.8);
        opacity: 1;

        &:hover {
          background: rgba($black, 0.2);
          color: $white;
        }
      }
      .expand-sidebar-btn {
        color: $primary;
        position: absolute;
        top: 19px;
        right: 4px;
        width: 28px;
        height: 28px;

        .collapse-sidebar-icon-field {
          width: 28px;
          height: 28px;
          background-color: $white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 8px 0px #00000014;

          .collapse-sidebar-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
      &::after {
        background: rgba($white, 0.15);
      }
    }

    // Logo wrapper

    .app-sidebar-logo {
      &--icon {
        background: unset;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--text {
        span {
          color: $white;
          opacity: 0.7;
        }

        b {
          color: $white;
        }
      }

      &:hover {
        color: $black;
      }
    }

    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background: $white !important;
    }
  }
}
