.label_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 24px;
  border-radius: 12px;
  border: 1px solid #dedede;
  width: 100%;
  max-width: 100%;

  .label_info {
    display: flex;
    gap: 8px;
    align-items: center;

    .label_icon {
      width: 32px;
      height: 32px;
    }

    .label_title {
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
      color: $text-100;
      margin: 0;
      padding: 0;
    }
  }

  .total_field {
    display: flex;
    align-items: center;
    justify-content: center;

    .total {
      margin: 0;
      padding: 0;
      font-weight: 800;
      font-size: 26px;
      line-height: 40px;
      color: $charleston-green;
    }
  }
}

.status_label {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  border-radius: 360px;
  width: fit-content;

  .status_name {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .label_point {
    width: 6px;
    height: 6px;

    border-radius: 50%;
  }
}

.details_label {
  width: 320px;

  @include phone {
    width: 100%;
  }
}

.success_label {
  background-color: $green-100;

  .success_point {
    background-color: $green-400;
  }

  .success_name {
    color: $green-400;
  }
}

.unpaid_label {
  background-color: $red-100;

  .unpaid_point {
    background-color: $system-red;
  }

  .unpaid_name {
    color: $system-red;
  }
}

.mark_as_paid_btn {
  border: 1px solid #202327;
  padding: 2px 12px;
  background-color: transparent;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: $text-100;

  &:disabled {
    opacity: 0.5;
  }
}
