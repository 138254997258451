.payments_header {
  display: flex;
  flex-direction: column;
  gap: 23px;

  .page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .payment_title {
      font-size: 26px;
      line-height: 40px;
      color: $text-100;
      font-weight: 800;
    }

    .calendar_button {
      display: flex;
      align-items: center;
      gap: 8px;
      border: none;
      background-color: $secondary-color;
      padding: 6px 12px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 22px;
      color: $text-100;

      &:focus {
        outline: none;
      }
    }
  }

  .labels_field {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include tablet {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
