.adjustments_form {
  display: flex;
  gap: 16px;
  margin-top: 16px;

  .value_field {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 192px;
  }

  .note_field {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .save_button {
    width: 54px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid $text-100;
    padding: 4px 12px;
    color: $text-100;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    background-color: $white;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
