.payments_page {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 28px 32px;

  @include minPhone {
    padding: 16px;
  }
}
