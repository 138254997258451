.tour_services {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;

  .section_title {
    font-weight: 800;
    font-size: 16px;
    line-height: 26px;
    color: $text-100;
    margin: 0;
  }

  .price {
    font-size: 14px;
    color: $text-100;
    line-height: 22px;
  }

  .package_name {
    color: $text-100;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .package_section {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;

    .package {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }

    .package_services_section {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;

      .package_service {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        box-sizing: border-box;

        .service_line {
          width: 100%;
          box-sizing: border-box;
          height: 0.5px;
          background-color: $divider_50;
        }

        .service_name_price {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .service_info {
          display: flex;
          gap: 4px;
          align-items: center;
          padding: 1px 6px;
          border-radius: 4px;
          background-color: $surface_medium;
          width: fit-content;

          .service_icon {
            width: 16px;
            height: 16px;
          }

          .service_element {
            font-size: 12px;
            line-height: 20px;
            color: $text-100;
          }
        }
      }
    }
  }

  .customize_options_section {
  }
}
