.payment_details_page {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 21px 32px;

  @include minPhone {
    padding: 16px;
  }

  .details_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    @include phone {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .header_left_side {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .left_side_top {
        display: flex;
        align-items: center;
        gap: 26px;

        @include phone {
          gap: 12px;
        }

        .back_icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .date_user_name {
          display: flex;
          align-items: center;
          gap: 11px;

          .date_section {
            color: $text_50;
            font-size: 16px;
            line-height: 24px;
          }

          .line_section {
            width: 1px;
            height: 14px;
            background-color: $divider_50;

            @include phone {
              display: none;
            }
          }

          .name_section {
            color: $text-100;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            @include phone {
              display: none;
            }
          }

          .name_section_details {
            font-weight: 400;
          }

          .tour_section {
            color: $text-100;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }

      .left_side_bottom {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        .avatar {
          width: 50px;
          height: 50px;
        }

        .user_name {
          padding: 0;
          margin: 0;
          font-size: 26px;
          font-weight: 800;
          line-height: 40px;
          color: $text-100;
        }
      }
    }

    .header_right_side {
      @include phone {
        width: 100%;
      }
    }
  }
}

.tour_details_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .header_left_side {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .left_side_top {
      display: flex;
      align-items: center;
      gap: 26px;

      @include phone {
        gap: 12px;
      }

      .back_icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .date_user_name {
        display: flex;
        align-items: center;
        gap: 11px;

        .date_section {
          color: $text_50;
          font-size: 16px;
          line-height: 24px;
        }

        .line_section {
          width: 1px;
          height: 14px;
          background-color: $divider_50;

          @include phone {
            display: none;
          }
        }

        .name_section {
          color: $text-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;

          @include phone {
            display: none;
          }
        }

        .name_section_details {
          font-weight: 400;
        }

        .tour_section {
          color: $text-100;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;

          @include phone {
            display: none;
          }
        }
      }
    }

    .left_side_bottom {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .tour_section {
        .tour_name {
          padding: 0;
          margin: 0;
          font-size: 26px;
          font-weight: 800;
          line-height: 40px;
          color: $text-100;

          @include minPhone {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }

      .tour_details {
        display: flex;
        gap: 16px;
        align-items: center;

        @include minPhone {
          flex-direction: column;
          width: 100%;
          gap: 8px;
        }

        .info_line {
          height: 40px;
          width: 1px;
          background-color: $divider_50;

          @include minPhone {
            height: 1px;
            width: 100%;
          }
        }

        .tour_info {
          display: flex;
          flex-direction: column;
          gap: 2px;

          @include minPhone {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }

          .top_section {
            display: flex;
            gap: 4px;
            align-items: center;

            .info_icon {
              width: 16px;
              height: 16px;
            }

            .title {
              color: $text_50;
              font-size: 14px;
              line-height: 22px;
              margin: 0;
            }
          }

          .bottom_section {
            .info {
              color: $text-100;
              font-size: 14px;
              line-height: 22px;
              margin: 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.loading_field {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
