.react-datepicker-popper {
  z-index: 20;
}

.react-datepicker {
  background-color: #e9eaea;

  .react-datepicker__header {
    background-color: #e9eaea;

    .react-datepicker__header__dropdown {
      margin-top: 10px;

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        border: none;
        border-radius: 6px;
        padding: 6px 20px 6px 12px;
        appearance: none;
        background: url("../images/payments/disclosure.svg") 95% center
          no-repeat;
        background-color: $secondary-color !important;
      }
    }
  }

  .react-datepicker__day--in-range {
    color: #007aff;
    background-color: #007aff1f !important;

    &:hover {
      color: #007aff;
      background-color: #007bff84;
    }
  }

  .react-datepicker__day--range-start {
    color: #007aff;
    background-color: #007aff1f;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;

    &:hover {
      color: #007aff;
      background-color: #007bff84;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }
  }

  .react-datepicker__day--range-end {
    color: #007aff;
    background-color: #007aff1f;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;

    &:hover {
      color: #007aff;
      background-color: #007bff84;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  .react-datepicker__day--today {
    color: #007aff;
  }

  .react-datepicker__day--selected {
    color: #007aff;
    background-color: #007aff1f;

    &:hover {
      color: #007aff;
      background-color: #007bff84;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__day--in-selecting-range {
    color: #007aff;
    background-color: #007aff1f;

    &:hover {
      color: #007aff;
      background-color: #007bff84;
    }
  }

  .react-datepicker__day--selecting-range-start {
    color: #007aff;
    background-color: #007aff1f;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
}
