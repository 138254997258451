// Mixins

@import "mixins/buttons";
@import "mixins/sizes";

@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin minPhone {
  @media (max-width: 480px) {
    @content;
  }
}
