.payment_table {
  overflow-y: overlay;
  height: calc(100dvh - 270px);
  border: 1px solid $border_2;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-height: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    width: 100%;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-left-radius: 10px;

    .header_row {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    tr {
      width: 100%;
      box-sizing: border-box;

      &:first-child {
        th {
          &:last-child {
            border-top-right-radius: 10px;
          }

          &:first-child {
            border-top-left-radius: 10px;
          }
        }
      }
    }

    .header_element {
      box-sizing: border-box;
      background-color: $surface-gray-3;
      text-align: start;
      white-space: nowrap;
      border: none;
      border-left: 1px solid $border_2;
      border-bottom: 1px solid $border_2;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $text_primary;

      &:first-child {
        border: none;
        border-bottom: 1px solid $border_2;
      }
    }

    .header_show_element {
      padding: 0;
      position: sticky;
      background-color: $surface-gray-3;
      top: 0;
      right: 0;
    }

    .table_body_element {
      padding: 10px 16px;
      font-size: 14px;
      line-height: 22px;
      width: calc(100% / 6);
      box-sizing: border-box;
    }

    .table_column {
      &:last-child {
        .table_element {
          border-bottom: none;
        }
      }
    }

    .table_element {
      border-left: 1px solid $border_2;
      border-bottom: 1px solid $border_2;
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text_primary;
      width: fit-content;
      white-space: nowrap;

      &:first-child {
        border: none;
        border-bottom: 1px solid $border_2;
      }

      .address_element {
        width: 172px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }

      .service_labels {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: nowrap;
      }
    }

    .show_column {
      padding: 0 12px;
      position: sticky;
      right: 0;
      text-align: center;
      background-color: $white;

      .show_icon {
        font-size: 0;
        cursor: pointer;
      }
    }
  }
}

.service_label {
  padding: 1px 6px;
  background-color: $surface_medium;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  color: $text-100;
  white-space: nowrap;
}
